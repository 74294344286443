@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
#select .active{
  background-color: #1FA24A;
  color:white;
  border-radius: "5px";
}

#select{
  background-color: #C0C6C2;
  color: #878C89;
  border-radius: "5px";
}
.dropin-parent {
  margin: auto;
}

.order-token {
  font-weight: 600;
}

.inputText {
  width: 200px;
}
.btn-render {
  border-radius: 6px;
  background-color: rgb(105, 51, 211);
  color: rgb(255, 255, 255);
  border: none;
  font-size: 14px;
  padding: 11px 16px;
}
input {
  padding: 0.67857143em 1em;
  border-radius: 6px;
  border: 1px solid #979797;
}
input:focus {
  outline-color: rgb(105, 51, 211);
}

.style-dropin {
  outline: 0;
  border-width: 0 0 1px;
  border-radius: 0px;
}

.style-dropin:focus {
  border-color: rgb(105, 51, 211);
}

.style-container {
  margin-bottom: 8px;
}

.mr-8 {
  margin-right: 0.5rem;
}

.mr-1 {
  margin-right: 1rem;
}

.mt-2 {
  margin-top: 2rem;
}
