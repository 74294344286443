.owl-stage-outer {
  width: 100% !important ;
}

.recent-search > .owl-carousel.owl-theme.owl-loaded.owl-drag > .owl-nav {
  position: absolute !important;
  top: -51px !important;
  right: 0px !important;
}

.recent-search > .owl-theme .owl-nav [class*="owl-"]:hover {
  background: transparent !important;
}

.recent-search > .owl-theme .owl-nav [class*="owl-"]:focus {
  outline: none !important;
}

.web-layout {
  background-image: url("../../assets/images/Ellipse 446.png");
  background-repeat: no-repeat;
  background-position: 0px -21px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: scroll;
  /* max-height: "602px"; */
}

.layout {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: scroll;
  /* max-height: "602px"; */
}
