.popup-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .popup-content {
    background-color: white;
    border-radius: 24px;
    padding: 22px 42px 38px 42px  ;
    width: 100%;
    max-width: 604px;
display: flex;
flex-direction: column;
   
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .popup-body {
    text-align: center;
  }
  
  .popup-title {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 42px;
  }
  
  .coins-display {
    background-color: #fff8f0;
    border-radius: 100px;
    padding: 12px;
    margin-bottom: 42px;
    display: flex;
    width: 240px;
    align-items: center;
    justify-content: center;
    gap: 8px;
  }
  
  .coins-amount {
    font-weight: 600;
  }
  
  .button-group {
    display: flex;
    gap: 16px;
  }
  
  .button {
    flex: 1;
    padding: 8px 16px;
    border-radius: 8px;
    border: none;
    cursor: pointer;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
  }
  
  .button-cancel {
    background-color: white;
    border: 1px solid #8B7862;
    color: #8B7862;
    ;
  }
  
  .button-cancel:hover {
    background-color: #f5f5f5;
  }
  
  .button-pay {
    background-color: #8B7862;
    color: white;
  }
  
  .button-pay:hover {
    background-color: #776b5d;
  }
  
  .container {
    padding: 16px;
  }