.react-datepicker.custom-calendar {
  border: none;
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
}

.react-datepicker.custom-calendar .react-datepicker__header {
  background: none;
}
.react-datepicker__month {
  margin: 1.7rem !important;
}

.react-datepicker__day-names {
  margin-top: 8px;
}

.react-datepicker__header {
  border: none;
}
